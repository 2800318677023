<template>
  <a-card :bordered="false">
    <a-form :model="where" :label-col="{ md: { span: 8 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 16 }, sm: { span: 24 } }">
      <a-row>
        <a-col :lg="8" :md="12" :sm="24" :xs="24">
          <a-form-item label="奖项名称:" name="luckName">
            <a-input v-model:value.trim="where.luckName" placeholder="请输入奖项名称" allow-clear />
          </a-form-item>
        </a-col>
        <a-col :lg="8" :md="12" :sm="24" :xs="24">
          <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
            <a-space>
              <a-button type="primary" @click="reload">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <!-- 表格 -->
    <ud-pro-table ref="table" row-key="luckId" :datasource="datasource" :columns="columns" v-model:selection="selection"
      :scroll="{ x: 'max-content' }">
      <template #toolbar>
        <a-space>
          <a-button type="primary" @click="openEdit()">
            <template #icon>
              <plus-outlined />
            </template>
            <span>新建</span>
          </a-button>
          <a-button type="primary" danger @click="removeBatch">
            <template #icon>
              <delete-outlined />
            </template>
            <span>删除</span>
          </a-button>
        </a-space>
      </template>
      <template #pic="{ record }">
        <div class="image">
          <a-image :src="record.goodsPic" :width="120" :height="90"></a-image>
        </div>
      </template>
      <template #action="{ record }">
        <a-space>
          <a @click="luckMake(record)">开奖</a>
          <a-divider type="vertical" />
          <a @click="openEdit(record)">修改</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确定要删除此项吗？" @confirm="remove(record)">
            <a class="ud-text-danger">删除</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a-popconfirm title="您确定要重置开奖，之前的开奖信息交被清空" @confirm="resetLuck(record)">
            <a class="ud-text-danger">重置开奖</a>
          </a-popconfirm>
        </a-space>
      </template>
    </ud-pro-table>
  </a-card>
  <luck-edit v-model:visible="showEdit" :data="current" :sceneId="sceneId" @done="reload"></luck-edit>
</template>

<script>
import * as luckApi from '@/api/scene/luck'
import { createVNode } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import luckEdit from './index-edit'
export default {
  name: 'luck-index',
  props: {
    // 会场ID
    sceneId: Object
  },
  components: {
    luckEdit
  },
  data() {
    return {
      // 表格列配置
      columns: [
        {
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.table.tableIndex + index
        },
        {
          title: '会场活动名称',
          dataIndex: 'sceneName',
          sorter: true
        },
        {
          title: '奖项名称',
          dataIndex: 'luckName',
          sorter: true
        },
        {
          title: '抽奖人',
          dataIndex: 'luckMaker',
          sorter: true

        },
        {
          title: '抽奖数',
          dataIndex: 'luckCount',
          sorter: true

        },
        {
          title: '状态',
          dataIndex: 'state',
          sorter: true

        },
        {
          title: '排序',
          dataIndex: 'sortNumber',
          sorter: true

        },
        {
          title: '奖品名称',
          dataIndex: 'goodsName',
          sorter: true

        },
        {
          title: '奖品图片',
          dataIndex: 'goodsPic',
          slots: { customRender: 'pic' }
        },
        {
          title: '展示大屏',
          dataIndex: 'screenId'
        },
        {
          title: '操作',
          width: 250,
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {},
      // 表格选中数据
      selection: [],
      // 是否显示现场
      showEdit: false,
      // 是否显示抽奖
      showLuck: false,
      // 是否显示详情弹窗
      showDetail: false,
      // 当前编辑数据
      current: null,
      detailInfo: null,
      // 编辑表单提交状态
      editLoading: false,
      corpTypeList: []
    }
  },
  watch: {
    sceneId() {
      this.reload()
    }
  },
  methods: {
    datasource(option, callback) {
      luckApi
        .page({
          sceneId: this.sceneId,
          ...this.where,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 搜索 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1, where: this.where })
    },
    /*  重置搜索 */
    reset() {
      this.where = {}
      this.reload()
    },
    /* 删除单个 */
    remove(row) {
      const hide = this.$message.loading('请求中..', 0)
      luckApi.deleteById(row.luckId).then(res => {
        hide()
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.reload()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        hide()
        this.$message.error(e.msg)
      })
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error('请至少选择一条数据')
        return
      }
      this.$confirm({
        title: '提示',
        content: '确定要删除选中的数据吗?',
        icon: createVNode(ExclamationCircleOutlined),
        maskClosable: true,
        onOk: () => {
          const hide = this.$message.loading('请求中..', 0)
          const data = { data: this.selection.map(d => d.luckId) }
          luckApi.deleteBatch(data).then(res => {
            hide()
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.reload()
            } else {
              this.$message.error(res.msg)
            }
          }).catch(e => {
            hide()
            this.$message.error(e.msg)
          })
        }
      })
    },
    /* 打开编辑弹窗 */
    openEdit(row) {
      this.current = row
      this.showEdit = true
    },
    luckMake(row) {
      const hide = this.$message.loading('请求中..', 0)
      const data = { activityId: row.activityId, luckId: row.luckId, sceneId: this.sceneId }
      luckApi.createLuckUser(data).then(res => {
        hide()
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.reload()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        hide()
        this.$message.error(e.msg)
      })
    },
    resetLuck(row) {
      const hide = this.$message.loading('请求中..', 0)
      const data = { activityId: row.activityId, luckId: row.luckId }
      luckApi.resetLuckUser(data).then(res => {
        hide()
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.reload()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        hide()
        this.$message.error(e.msg)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-modal-body {
  padding: 0;
}

:deep(.ant-image-img) {
  height: 100%;
}
</style>
